import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { SmallText, SubTitle } from '../../config/theme/globalStyles';
import Button from '../../components/Button';
import {
  ButtonContainer,
  Container,
  ErrorMessage,
  InfoMessage,
  CharacterCount,
} from './style';
import {
  addDoc,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
} from 'firebase/firestore';
import { firestore } from '../../services/firebase';
import MockupSmartphone from '../../components/MockupSmartphone';
import { ContainerInputs } from './style';
import { ContainerMockup } from './style';
import { ContainerLayout } from './style';
import FlatListMultipleSelection from '../ClickCard/FlatListMultipleSelection';
import { CheckBox } from 'react-native-elements';
import TextInput from '../../components/TextInput';
import { useCustomTheme } from '../../contexts/useCustomTheme';
import theme from '../../config/theme';
import { Dimensions } from 'react-native';
import { removeSansSerif } from '../../utils';

const MAX_TITLE_LENGTH = 65;
const MAX_MESSAGE_LENGTH = 178;

const Notifications = () => {
  const [error, setError] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deeplink, setDeeplink] = useState('');
  const [isDeeplink, setIsDeeplink] = useState(false);

  const [sendAllUser, setSendAllUsers] = useState(false);
  const [sendFilteredUser, setSendFilteredUsers] = useState(false);

  const [isVisible, setIsVisible] = useState(
    Dimensions.get('window').width > 1024
  );

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get('window').width
  );

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(Dimensions.get('window').width);
    };

    const subscription = Dimensions.addEventListener(
      'change',
      updateScreenWidth
    );
    return () => subscription?.remove();
  }, []);

  useEffect(() => {
    const updateLayout = () => {
      setIsVisible(Dimensions.get('window').width > 1024);
    };

    const subscription = Dimensions.addEventListener('change', updateLayout);
    return () => subscription?.remove();
  }, []);

  const { customFont } = useCustomTheme();

  
  useEffect(() => {
    const fetchProducts = async () => {
      const response = await getDocs(collection(firestore, 'products'));
      const products = response.docs
        .map((doc) => {
          const data = doc.data();
          return {
            productId: data.productId || doc.id,
            name: data.name,
            marketplace: data?.marketplace ? data?.marketplace : "STRIPE"
          };
        });

      setAllProducts(products);
    };

    fetchProducts();
  }, [sendAllUser]);


  const formatDeeplink = (url) => {
    if (url.includes('CommunityComments')) {
      const index = url.indexOf('CommunityComments');
      setDeeplink(url.substring(index));
    } else if (url.includes('Aula?')) {
      const index = url.indexOf('Aula?');
      setDeeplink(url.substring(index));
    } else {
      setDeeplink(null);
    }
  };

  const handleSendNotification = async () => {
    const filterProducts = allProducts.filter((e) => e.selected == true);
    const allProductsIds = filterProducts.map((e) => e.productId);
    const notificationData = {
      title,
      body: content,
      sendDate: new Date(),
      status: 'pending',
      type: 'schedulingAdmin',
      productsIds: allProductsIds,
      deeplink: deeplink ? deeplink : '',
    };

    try {
      setLoading(true);
      const notificationsRef = collection(firestore, 'notifications');
      await addDoc(notificationsRef, notificationData);
      setTitle('');
      setContent('');
      setDeeplink('');
      setSendAllUsers(false);
      setSendFilteredUsers(false);
      setIsDeeplink(false);
    } catch (error) {
      console.error('Erro ao enviar a notificação:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSend = async () => {
    if (!title || !content) {
      setError('Título e conteúdo são obrigatórios.');
      return;
    }

    if (!sendAllUser && !sendFilteredUser) {
      return setError(
        'Você deve selecionar quais alunos vão receber a notificação'
      );
    }

    try {
      const notificationsRef = collection(firestore, 'notifications');
      const lastDocQuery = query(
        notificationsRef,
        orderBy('sendDate', 'desc'),
        limit(1)
      );
      const querySnapshot = await getDocs(lastDocQuery);

      if (!querySnapshot.empty) {
        const lastDoc = querySnapshot.docs[0];
        const lastDocData = lastDoc.data();

        if (
          lastDocData.status === 'pending' ||
          lastDocData.status === 'processing'
        ) {
          setError(
            'Você tem uma notificação sendo enviada nesse momento, tente novamente mais tarde.'
          );
          return;
        }

        const lastSendDate = lastDocData.sendDate.toDate();
        const now = new Date();
        const timeDifference = now - lastSendDate;
        const minutesDifference = timeDifference / (1000 * 60);

        if (minutesDifference < 15) {
          setError(
            'O servidor só permite envio de notificações de 15 em 15 minutos.'
          );
          return;
        }
      }
      setError('');
      handleSendNotification();
    } catch (error) {
      console.error('Erro ao verificar o status das notificações:', error);
      setError('Ocorreu um erro ao verificar o status das notificações.');
    }
  };

  return (
    <Container style={{ backgroundColor: 'white' }}>
      <ScrollView>
        <View style={{ padding: 16, marginTop: 20 }}>
          <SubTitle
            padding="0rem 0rem 0.5rem 0rem"
            style={{
              fontFamily: removeSansSerif(
                customFont && customFont.fontFamilyTitle
              )
                ? removeSansSerif(customFont.fontFamilyTitle)
                : theme.FONTFAMILY.BOLD,
            }}
          >
            Painel de notificações:
          </SubTitle>
        </View>

        <ContainerLayout style={!isVisible ? { justifyContent: 'center' } : {}}>
          <ContainerInputs>
            <View style={{ marginBottom: 16 }}>
              <SmallText
                textAlign="flex-start"
                color="black"
                fontFamily={
                  removeSansSerif(customFont && customFont.fontFamilyText)
                    ? removeSansSerif(customFont.fontFamilyText)
                    : theme.FONTFAMILY.REGULAR
                }
              >
                Título:
              </SmallText>
              <InfoMessage
                style={{
                  fontFamily: removeSansSerif(
                    customFont && customFont.fontFamilyText
                  )
                    ? removeSansSerif(customFont.fontFamilyText)
                    : theme.FONTFAMILY.REGULAR,
                }}
              >
                Um título curto e agradável que resume a mensagem de notificação
              </InfoMessage>
              <TextInput
                color="black"
                backgroundColor="white"
                placeholder="Título"
                keyboardType="default"
                returnKeyType="go"
                value={title}
                onChangeText={setTitle}
                maxLength={MAX_TITLE_LENGTH}
                style={{
                  fontFamily: removeSansSerif(
                    customFont && customFont.fontFamilyText
                  )
                    ? removeSansSerif(customFont.fontFamilyText)
                    : theme.FONTFAMILY.REGULAR,
                }}
              />

              <CharacterCount
                style={{
                  fontFamily: removeSansSerif(
                    customFont && customFont.fontFamilyText
                  )
                    ? removeSansSerif(customFont.fontFamilyText)
                    : theme.FONTFAMILY.REGULAR,
                }}
              >
                {MAX_TITLE_LENGTH - title.length} caracteres restantes
              </CharacterCount>
            </View>

            <View style={{ marginBottom: 16 }}>
              <SmallText textAlign="flex-start" color="black">
                Mensagem:
              </SmallText>
              <InfoMessage
                style={{
                  fontFamily: removeSansSerif(
                    customFont && customFont.fontFamilyText
                  )
                    ? removeSansSerif(customFont.fontFamilyText)
                    : theme.FONTFAMILY.REGULAR,
                }}
              >
                Uma mensagem curta com detalhes da notificação
              </InfoMessage>
              <TextInput
                color="black"
                backgroundColor="white"
                placeholder="Mensagem"
                keyboardType="default"
                returnKeyType="go"
                value={content}
                onChangeText={setContent}
                maxLength={MAX_MESSAGE_LENGTH}
                fontFamily={
                  removeSansSerif(customFont && customFont.fontFamilyText)
                    ? customFont.fontFamilyText
                    : theme.FONTFAMILY.REGULAR
                }
              />
              <CharacterCount
                style={{
                  fontFamily: removeSansSerif(
                    customFont && customFont.fontFamilyText
                  )
                    ? removeSansSerif(customFont.fontFamilyText)
                    : theme.FONTFAMILY.REGULAR,
                }}
              >
                {MAX_MESSAGE_LENGTH - content.length} caracteres restantes
              </CharacterCount>
            </View>

            <View>
              <SmallText textAlign="flex-start" color="black">
                Selecione para quais alunos você deseja enviar a notificação:
              </SmallText>
              <InfoMessage>
                Filtre os alunos que receberão a notificação. Você pode enviar
                para todos ou para um grupo especifico
              </InfoMessage>

              <View
                style={{
                  flexDirection: screenWidth < 640 ? 'column' : 'row',
                  alignItems: screenWidth < 640 ? '' : 'center',
                }}
              >
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <CheckBox
                    checked={sendAllUser}
                    onPress={() => {
                      setSendAllUsers(!sendAllUser);
                      setSendFilteredUsers(false);
                    }}
                    checkedColor="#008000"
                  />

                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: removeSansSerif(
                        customFont && customFont.fontFamilyText
                      )
                        ? removeSansSerif(customFont.fontFamilyText)
                        : theme.FONTFAMILY.REGULAR,
                    }}
                  >
                    Todos os alunos
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <CheckBox
                    checked={sendFilteredUser}
                    onPress={() => {
                      setSendFilteredUsers(!sendFilteredUser);
                      setSendAllUsers(false);
                    }}
                    checkedColor="#008000"
                  />

                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: removeSansSerif(
                        customFont && customFont.fontFamilyText
                      )
                        ? removeSansSerif(customFont.fontFamilyText)
                        : theme.FONTFAMILY.REGULAR,
                    }}
                  >
                    Desejo selecionar um produto ou plano
                  </Text>
                </View>
              </View>
            </View>

            {sendFilteredUser && (
              <>
                <FlatListMultipleSelection
                  items={allProducts}
                  setItems={setAllProducts}
                  smallText="Produto Selecionado:"
                  footerText="Selecione o produto"
                  notification={true}
                />
              </>
            )}

            <View>
              <SmallText textAlign="flex-start" color="black">
                Link(Opcional):
              </SmallText>

              <InfoMessage>
                Informe o link de um conteúdo ou post da comunidade. Quando seu
                aluno clicar na notificação, será redirecionado para lá
              </InfoMessage>

              <View style={{ marginBottom: 16 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <CheckBox
                    checked={isDeeplink}
                    onPress={() => {
                      setIsDeeplink(!isDeeplink);
                    }}
                    checkedColor="#008000"
                  />

                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: removeSansSerif(
                        customFont && customFont.fontFamilyText
                      )
                        ? removeSansSerif(customFont.fontFamilyText)
                        : theme.FONTFAMILY.REGULAR,
                    }}
                  >
                    Desejo redirecionar o aluno para uma tela do aplicativo
                  </Text>
                </View>

                {isDeeplink && (
                  <>
                    <TextInput
                      color="black"
                      backgroundColor="white"
                      placeholder="Cole o link aqui..."
                      keyboardType="default"
                      returnKeyType="go"
                      value={deeplink}
                      onChangeText={(value) => formatDeeplink(value)}
                    />
                  </>
                )}
              </View>
            </View>
          </ContainerInputs>

          {isVisible && (
            <ContainerMockup>
              <MockupSmartphone
                title={title}
                content={content}
              ></MockupSmartphone>
            </ContainerMockup>
          )}
        </ContainerLayout>
      </ScrollView>

      {error ? <ErrorMessage>{error}</ErrorMessage> : null}

      <ButtonContainer>
        <Button title="Enviar" onPress={handleSend} disabled={loading} />
      </ButtonContainer>
    </Container>
  );
};

export default Notifications;
